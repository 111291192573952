/*======================================
    Buttons
======================================*/

%btn {
    --btn-bg-color: var(--brand-blue-light);
    --btn-bg-color-hover: var(--brand-blue-light-alt);
    --btn-border-color: var(--brand-blue-light);
    --btn-border-color-hover: var(--brand-blue-light-alt);
    --btn-border-radius: var(--border-radius);
    --btn-text-color: var(--text-color);
    --btn-text-color-hover: var(--text-color);
    --btn-icon-caret-left: url('../images/ui/caret-hw-left-black.svg');
    --btn-icon-caret-right: url('../images/ui/caret-hw-right-black.svg');

    background-color: var(--btn-bg-color);
    border: 1px solid var(--btn-border-color);
    border-radius: var(--btn-border-radius);
    color: var(--btn-text-color);
    cursor: pointer;
    display: inline-block;
    font-size: rem(15px);
    font-weight: var(--fontweight-normal);
    line-height: 1;
    margin: 0 1em 1em 0;
    padding: 0.75em 1.5em 0.75em;
    text-align: center;
    text-decoration: none;
    transition: background-color 250ms ease, border-color 250ms ease, color 250ms ease;
    white-space: nowrap;

    &:hover {
        background-color: var(--btn-bg-color-hover);
        border-color: var(--btn-border-color-hover);
        color: var(--btn-text-color-hover);
    }

    &:focus-visible {
        outline: 2px solid var(--brand-green);
        outline-offset: 0.25rem;
    }
}

%btn-black {
    --btn-bg-color: var(--gray-900);
    --btn-bg-color-hover: var(--gray-700);
    --btn-border-color: var(--gray-900);
    --btn-border-color-hover: var(--gray-700);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-icon-caret-left: url('../images/ui/caret-hw-left-white.svg');
    --btn-icon-caret-right: url('../images/ui/caret-hw-right-white.svg');
}

%btn-white {
    --btn-bg-color: var(--white);
    --btn-bg-color-hover: var(--gray-200);
    --btn-border-color: var(--white);
    --btn-border-color-hover: var(--gray-200);
    --btn-text-color: var(--gray-900);
    --btn-text-color-hover: var(--gray-900);
    --btn-icon-caret-left: url('../images/ui/caret-hw-left-black.svg');
    --btn-icon-caret-right: url('../images/ui/caret-hw-right-black.svg');
}

%btn-outline-black {
    --btn-bg-color: transparent;
    --btn-bg-color-hover: var(--gray-900);
    --btn-border-color: var(--gray-900);
    --btn-border-color-hover: var(--gray-900);
    --btn-text-color: var(--gray-900);
    --btn-text-color-hover: var(--white);
    --btn-icon-caret-left: url('../images/ui/caret-hw-left-black.svg');
    --btn-icon-caret-right: url('../images/ui/caret-hw-right-black.svg');
    --btn-icon-caret-left-hover: url('../images/ui/caret-hw-left-white.svg');
    --btn-icon-caret-right-hover: url('../images/ui/caret-hw-right-white.svg');

}

%btn-outline-white {
    --btn-bg-color: transparent;
    --btn-bg-color-hover: var(--white);
    --btn-border-color: var(--white);
    --btn-border-color-hover: var(--white);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--gray-900);
    --btn-icon-caret-left: url('../images/ui/caret-hw-left-white.svg');
    --btn-icon-caret-right: url('../images/ui/caret-hw-right-white.svg');
    --btn-icon-caret-left-hover: url('../images/ui/caret-hw-left-black.svg');
    --btn-icon-caret-right-hover: url('../images/ui/caret-hw-right-black.svg');
}

%btn-icon {
    background-image: var(--btn-icon-caret-right);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 0.6em;
    height: rem(40px);
    overflow: hidden;
    text-indent: -9999px;
    width: rem(47px);
}


.btn {
    @extend %btn;

    &-black {
        @extend %btn-black;
    }

    &-white {
        @extend %btn-white;
    }

    &-outline-black {
        @extend %btn-outline-black;
    }

    &-outline-white {
        @extend %btn-outline-white;
    }

    &-icon {
        @extend %btn-icon;

        &.icon-caret-left {
            background-image: var(--btn-icon-caret-left);

            &.btn-outline-black,
            &.btn-outline-white {
                &:hover {
                    background-image: var(--btn-icon-caret-left-hover);
                }
            }
        }

        &,
        &.icon-caret-right {
            background-image: var(--btn-icon-caret-right);

            &.btn-outline-black,
            &.btn-outline-white {
                &:hover {
                    background-image: var(--btn-icon-caret-right-hover);
                }
            }
        }
    }

    &-search {
        background-color: var(--btn-border-color);
        background-image: url('../images/ui/search-white.svg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 1rem;
        border: 0;
        min-width: 3rem;
        overflow: hidden;
        text-indent: -9999px;
    }

    .centered & {
        margin: 0 0.5rem 0.5rem;
    }

    &[disable],
    &.slick-disabled {
        --btn-bg-color: var(--gray-300);
        --btn-border-color: var(--gray-300);
        --btn-text-color: var(--gray-600);
        --btn-bg-color-hover: var(--gray-300);
        --btn-border-color-hover: var(--gray-300);
        --btn-text-color-hover: var(--gray-400);

        cursor: default;
        pointer-events: none;
        touch-action: none;
    }
}
