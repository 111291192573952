:root {
    // Neutrals
    --black: #000;
    --black-3: rgba(0, 0, 0, 0.03);
    --black-5: rgba(0, 0, 0, 0.05);
    --black-10: rgba(0, 0, 0, 0.1);
    --black-20: rgba(0, 0, 0, 0.2);
    --black-30: rgba(0, 0, 0, 0.3);
    --black-40: rgba(0, 0, 0, 0.4);
    --black-50: rgba(0, 0, 0, 0.5);
    --black-60: rgba(0, 0, 0, 0.6);
    --black-70: rgba(0, 0, 0, 0.7);
    --black-80: rgba(0, 0, 0, 0.8);
    --black-90: rgba(0, 0, 0, 0.9);

    --gray-100: #f8f8f8;
    --gray-200: #efefef;
    --gray-300: #d5d5d5;
    --gray-400: #959595;
    --gray-600: #585858;
    --gray-700: #2b2b2b;
    --gray-800: #1f1f1f;
    --gray-900: #121212;

    --white: #fff;
    --white-5: rgba(255, 255, 255, 0.05);
    --white-10: rgba(255, 255, 255, 0.1);
    --white-20: rgba(255, 255, 255, 0.2);
    --white-30: rgba(255, 255, 255, 0.3);
    --white-40: rgba(255, 255, 255, 0.4);
    --white-50: rgba(255, 255, 255, 0.5);
    --white-60: rgba(255, 255, 255, 0.6);
    --white-70: rgba(255, 255, 255, 0.7);
    --white-80: rgba(255, 255, 255, 0.8);
    --white-90: rgba(255, 255, 255, 0.9);

    // Theme
    --brand-green: #7FBB42;
    --brand-red: #8A2332;
    --brand-yellow: #F4CD3E;
    --brand-orange: #ED8B49;

    --brand-blue-light: #4CB6E0;
    --brand-blue-light-alt: #79d0f3;


    // Gradients
    // --grad-gray: linear-gradient(135deg, var(--gray-100) 0%, var(--gray-200) 100%);


    // Fonts, weights
    --font-salted: 'salted', sans-serif;
    --font-specter: 'specter', sans-serif;
    --font-usual: 'usual', sans-serif;

    --font-sans: var(--font-usual);
    --font-handwritten: var(--font-salted);

    --fontweight-normal: 400;   // Usual, Salted
    --fontweight-semibold: 600; // Usual
    --fontweight-bold: 600;     // Usual
    --fontweight-black: 800;    // Specter
    --fontweight-ultra: 900;    // Specter


    // Typography, links
    --text-color: var(--gray-900);
    --link-color: #026ebb;
    --link-color-hover: var(--blue-accent);
    --link-color-visited: var(--purple-600);

    // Spacing
    --block-spacing-sm: 2rem;
    --block-spacing-md: 4rem;
    --block-spacing-lg: 6rem;
    --block-spacing-xl: 8rem;
    --block-spacing-xxl: 10rem;
    --block-spacing-sm-neg: -2rem;
    --block-spacing-md-neg: -4rem;
    --block-spacing-lg-neg: -6rem;
    --block-spacing-xl-neg: -8rem;
    --block-spacing-xxl-neg: -10rem;

    // Utility
    --red-600: #a50034;
    --purple-600: #402885;

    // Misc
    --border-radius: 0.375rem;
}

// Breakpoints
$bp-xxs: 22.5em;   // 360px
$bp-xs: 30em;   // 480px
$bp-sm: 40em;   // 640px
$bp-md: 50em;   // 800px
$bp-lg: 60em;   // 960px
$bp-xl: 70em;   // 1120px
$bp-xxl: 80em;  // 1280px
$bp-xxxl: 90em; // 1440px
