// BG colors
%has-bg-color {
    background-color: var(--section-background-color);
}

// Generic for custom color
.has-bg-color {
    @extend %has-bg-color;
}

.has-bg-color-white {
    @extend %has-bg-color;
    --handwritten-font-color: var(--brand-green);
    --section-background-color: var(--white);

    + .has-bg-color-white {
        padding-top: 0;
    }
}

.has-bg-color-gray {
    @extend %has-bg-color;
    --handwritten-font-color: var(--brand-green);
    --section-background-color: var(--gray-200);
}

.has-bg-color-green {
    @extend %has-bg-color;
    --handwritten-font-color: var(--white);
    --section-background-color: var(--brand-green);

    a,
    .btn {
        &:focus-visible {
            outline: 2px solid var(--white);
            outline-offset: 0.25rem;
        }
    }
}

.has-bg-color-blue {
    @extend %has-bg-color;
    --section-background-color: var(--brand-blue-light);

    .preheading {
        --handwritten-font-color: var(--white);
    }
}

.has-bg-color-gray-dark {
    @extend %has-bg-color;
    --section-background-color: var(--gray-800);

    .preheading {
        --handwritten-font-color: var(--brand-green);
    }
}

.has-bg-color-black {
    @extend %has-bg-color;
    --section-background-color: var(--gray-900);

    .preheading {
        --handwritten-font-color: var(--brand-green);
    }
}

.has-bg-color-orange {
    @extend %has-bg-color;
    --handwritten-font-color: var(--white);
    --section-background-color: var(--brand-orange);
}

// Noise BG image
%has-bg-noise-dark {
    background-image: url('../images/bg/noise-dark.png');
    background-position: center top;
    background-size: 1000px auto;
}

%has-bg-noise-light {
    background-image: url('../images/bg/noise-light.png');
    background-position: center top;
    background-size: 1000px auto;
}

.has-bg-noise-dark {
    @extend %has-bg-noise-dark;
}

.has-bg-noise-light {
    @extend %has-bg-noise-light;
}

// Dark BG text overrides
%has-bg-dark {
    --text-color: var(--white);

    color: var(--text-color);

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    p,
    li,
    figcaption,
    address {
        color: var(--text-color);
    }

    a {
        &:focus-visible {
            outline: 2px solid var(--brand-green);
            outline-offset: 0.25rem;
        }
    }

    a:not([class]) {
        color: var(--brand-blue-light);
    }

    blockquote {
        p {

            &:first-of-type {
                &::before {
                    color: var(--white-50);
                }
            }

            &:last-of-type {
                &::after {
                    color: var(--white-50);
                }
            }
        }
    }

    table {
        td {
            color: var(--white);
        }
    }

    .btn {
        //@extend %btn-white;
        --btn-text-color: var(--gray-900);
        --btn-text-color-hover: var(--gray-900);
    }

    .btn-outline-white {
        --btn-text-color: var(--text-color);
    }

    hr {
        background-color: var(--white);
        border-top: 1px solid var(--white);
        color: var(--white);
    }

    form {
        --form-border-color: var(--white);

        input,
        textarea {
            color: var(--blue-600);
        }
    }
}

.has-bg-dark {
    @extend %has-bg-dark;
}
